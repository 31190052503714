import React from 'react';

import { PageLayout } from 'components/common';

const ContactPage = () => {
    
    return ( 
        <PageLayout>
        </PageLayout>
    )
}

export {ContactPage};
